import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {Combination} from '../../../models/combination';
import {Answer} from '../models/answer';
import {ApiService} from '../../../services/api.service';
import {CreatingLesson, DynamicRaise} from '../models/creatingLesson';
import {DefaultDataService, ActionColors} from '../../../services/default-data.service';
import {Chart} from '../../../models/chart/chart';
import {ChartPack} from '../../../models/chart/chart-pack';
import {MixedChart} from '../../../models/chart/mixed-chart';
import {StackRange} from '../models/stack-range';
import {HandPrompter} from '../models/hand-promter';
import {Chapter} from '../models/chapter';
import {CreatingAsymmetricLesson} from '../models/creating-asymmetric-lesson';
import {AsymmetricStackRange} from '../models/asymmetric-stack-range';
import {Action} from '../../../models/action/action';
import {MttChart} from '../../../models/chart/mtt-chart';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ChartsService {

  savedRanges: StackRange[] = undefined;
  savedAsymmetricRanges: AsymmetricStackRange[] = undefined;

  constructor(private apiService: ApiService, private defaultDataService: DefaultDataService) {}



  getDefaultCombinations(): Combination[] {
    return this.defaultDataService.getDefaultCombinations();
  }

  getDefaultAnswers(): Answer[] {

    return [
      {id: 3, title: 'Fold', color: {key: 'fold', value: ActionColors.FOLD}, isRandom: 'n', value: 'f'},
      {id: 0, title: 'Call', color: {key: 'call', value: ActionColors.CALL}, isRandom: 'n', value: 'c'},
      {id: 1, title: 'Raise', color: {key: 'raise', value: ActionColors.RAISE}, factor: 2, isRandom: 'n', value: 'r'},
      {id: 2, title: 'All-in', color: {key: 'all-in', value: ActionColors.ALLIN}, isRandom: 'n', value: 'a'}
      ];
  }

  getDefaultVs3bet(): Answer[] {

    return [
      {id: 3, title: 'Fold', color: {key: 'fold', value: ActionColors.FOLD}, isRandom: 'n', value: 'f'},
      {id: 0, title: 'Call', color: {key: 'call', value: ActionColors.CALL}, isRandom: 'n', value: 'c'},
      {id: 2, title: 'All-in', color: {key: 'all-in', value: ActionColors.ALLIN}, isRandom: 'n', value: 'a'}
    ];
  }

  getDefaultVsAll(): Answer[] {

    return [
      {id: 3, title: 'Fold', color: {key: 'fold', value: ActionColors.FOLD}, isRandom: 'n', value: 'f'},
      {id: 0, title: 'Call', color: {key: 'call', value: ActionColors.CALL}, isRandom: 'n', value: 'c'}
    ];
  }

  createLesson(newLesson: CreatingLesson): Observable<any> {
    return this.apiService.createLesson(newLesson);
  }

  createAsymmetricLesson(newAsymmetricLesson: CreatingAsymmetricLesson): Observable<any> {
    return this.apiService.createAsymmetricLesson(newAsymmetricLesson);
  }

  createMixedLesson(newLesson: MixedChart): Observable<any> {
    return this.apiService.createMixedLesson(newLesson);
  }

  saveMixLessonAsymmetric(newLesson: MixedChart): Observable<any> {
    return this.apiService.saveMixLessonAsymmetric(newLesson);
  }

  deletePack(packId: number): Observable<any> {
    return this.apiService.deletePack(packId);
  }

  copyPack(packId: number, title: string): Observable<ChartPack> {
    return this.apiService.copyPack(packId, title);
  }

  editPack(packId: number, title: string): Observable<ChartPack> {
    return this.apiService.editPack(packId, title);
  }

  deleteLesson(lessonId: number): Observable<any> {
    return this.apiService.deleteLesson(lessonId);
  }

  deleteMixedLesson(lessonId: number): Observable<any> {
    return this.apiService.deleteMixedLesson(lessonId);
  }

  getChartGroup(groupId: number): Observable<{charts: Chart[], mixCharts: Chart[],
    uuid?: string, isEditable?: boolean}> {
    return this.apiService.getChartGroup(groupId);
  }

  getAsymmetricChartGroup(groupId: number, userId: number): Observable<{charts: Chart[], mixCharts: Chart[],
    uuid?: string, isEditable?: boolean}> {
    return this.apiService.getAsymmetricChartGroup(groupId, userId);
  }

  getChapters(groupChartId: string | number): Observable<Chapter[]> {
    return this.apiService.getChapters(groupChartId);
  }

  getActions(packId: string = null): Observable<Action[]> {
    return this.apiService.getActions(packId);
  }

  getActionChartsAndRaises(id: number): Observable<{charts: MttChart[], raises: DynamicRaise[]}> {
    return this.apiService.getAction(id).pipe(
      map((action: Action) => {
        const charts: MttChart[] = [];
        const raises: DynamicRaise[] = [];

        for (const actionChart of action.charts) {
          const newChart: MttChart = {};
          newChart.actionChartId = actionChart.id;
          newChart.title = actionChart.title;
          newChart.combinations = this.defaultDataService.getEmptyChart();
          newChart.answers = this.getDefaultAnswers();
          newChart.easy = 1;
          newChart.normal = 1;
          newChart.hard = 1;
          charts.push(newChart);
        }

        for (const settings of action.playerSettings) {
          if (settings.actionFactor === 0) {
            raises.push({position: settings.position, amount: null});
          }
        }

        if (action.dynamicAction && action.dynamicAction.action.factor === 0) {
          action.dynamicAction.positions.forEach((position) => {
            raises.push({position, amount: null});
          });
        }

        return {charts, raises};
      })
    );
  }

  getChartGroups(userId: number): Observable<ChartPack[]> {
    return this.apiService.getChartGroups(userId);
  }

  getLessonsByPhase(packId: number, phase: number, positionId: number): Observable<Chart[]> {
    return this.apiService.getChartGroup(packId).pipe(
      map((data: {
        charts: Chart[], mixCharts: Chart[],
        uuid?: string, isEditable?: boolean
      }) => data.charts.filter((chart: Chart) => chart.action.phase === phase && chart.action.heroPosition.id === positionId))
    );
  }

  getChartById(id: number): Observable<Chart> {
    return this.apiService.getChartById(id);
  }

  getMixedChartById(id: number): Observable<MixedChart> {
    return this.apiService.getMixedChartById(id);
  }

  createChartGroup(chartPack: ChartPack): Observable<ChartPack> {
    return this.apiService.createChartGroup(chartPack);
  }

  getFlashAnswer(packId: number, effectiveStack: number, hand: number): Observable<HandPrompter> {
    return  this.apiService.getFlashAnswer(packId, effectiveStack, hand);
  }

  saveRanges(ranges: StackRange[]) {
    this.savedRanges = ranges;
  }

  saveChapters(chapters: Chapter[], groupChartId: string): Observable<any> {
    return this.apiService.saveChapters(chapters, groupChartId);
  }

}
